import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['addOnContainer', 'addOnRow', 'timeslotContainer', 'timeslotTitle', 'timeslotInput'];

  static values = {
    url: String,
    name: String,
    model: String,
    timeslotUrl: String,
    defaultRowCount: Number
  }

  connect() {
    this.rowCount = this.defaultRowCountValue;
  }

  buildQueryURL(packageId) {
    const url = new URL(this.urlValue);
    url.searchParams.set('name', this.nameValue);
    url.searchParams.set('model_name', this.modelValue);
    url.searchParams.set('add_on_index', this.rowCount);
    url.searchParams.set('target', this.addOnContainerTarget.id);
    url.searchParams.set('package_id', packageId); // 添加 package_id 作為查詢參數
    url.pathname = `${url.pathname}.turbo_stream`;
    return url.toString();
  }

  buildTimeslotQueryURL(addOnId, addOnIndex, targetId) {
    const url = new URL(this.timeslotUrlValue);
    url.searchParams.set('model_name', this.modelValue);
    url.searchParams.set('add_on_id', addOnId);
    url.searchParams.set('add_on_index', addOnIndex);
    url.searchParams.set('target', targetId);
    url.pathname = `${url.pathname}.turbo_stream`;
    return url.toString();
  }

  add(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const packageId = button.dataset.packageId; // 獲取 package_id
  
    const url = this.buildQueryURL(packageId);
    if (!url) return;

    this.rowCount = this.rowCount + 1;
    Turbo.visit(url, {
      historyChanged: true
    });
  }

  remove(event) {
    event.preventDefault();
    for(const addOnRow of this.addOnRowTargets) {
      if (addOnRow.dataset.packageAddOnRow === event.currentTarget.dataset.packageAddOnRow) {
        addOnRow.remove()
      }
    }
  }

  change(event) {
    event.preventDefault();
    for(const timeslotContainer of this.timeslotContainerTargets) {
      if (timeslotContainer.dataset.packageAddOnRow === event.currentTarget.dataset.packageAddOnRow) {
        const url = this.buildTimeslotQueryURL(
          event.currentTarget.value,
          event.currentTarget.dataset.addOnIndex,
          timeslotContainer.id
        );
        if (!url) return;

        Turbo.visit(url, {
          historyChanged: true
        });
      }
    }
  }

  toggleTimeslot(event) {
    event.preventDefault();
    const target = event.currentTarget

    if (target.classList.contains("untoggle")) {
      target.classList.remove("untoggle");
      target.classList.add("toggle");
      for(const timeslotInput of this.timeslotInputTargets) {
        if (timeslotInput.dataset.packageAddOnRow === event.currentTarget.dataset.packageAddOnRow) {
          const value = JSON.parse(timeslotInput.value)
          value.push(event.currentTarget.dataset.timeslotId)
          timeslotInput.value = JSON.stringify(value)
        }
      }
    } else {
      target.classList.remove("toggle");
      target.classList.add("untoggle");
      for(const timeslotInput of this.timeslotInputTargets) {
        if (timeslotInput.dataset.packageAddOnRow === event.currentTarget.dataset.packageAddOnRow) {
          const value = JSON.parse(timeslotInput.value)
          timeslotInput.value = JSON.stringify(value.filter(v => v !== event.currentTarget.dataset.timeslotId))
        }
      }
    }

  }
}
